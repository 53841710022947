<script>
    import appConfig from "@/app.config.json";
    import Stat from "@/components/stat";
    import helper from "@/assets/helpers/helper";

    export default {
        page: {
            title: "Statictics Competition",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: "statisticsCompetition",
        components: {Stat},
        data() {
            return {
                title: "Statictics Competition ",
                submissions : {
                    series: [],
                    chartOptions: {
                        labels: ["Solves", "Failes"],
                        colors: ["#34c38f","#f46a6a", "#556ee6" , "#50a5f1", "#f1b44c"],
                        legend: {
                            show: true,
                            position: 'bottom',
                            horizontalAlign: 'center',
                            verticalAlign: 'middle',
                            floating: false,
                            fontSize: '14px',
                            offsetX: 0,
                            offsetY: -10
                        },

                        responsive: [{
                            breakpoint: 600,
                            options: {
                                chart: {
                                    height: 240
                                },
                                legend: {
                                    show: false
                                },
                            }
                        }]
                    }
                },
                namecomp:'',
                most_solved:{
                    challenge:{
                        name:''
                    }
                },
                score_distribution : {
                    series: [],
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            offsetY: 28,
                            colors: {
                                backgroundBarRadius: 10,
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: true,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true
                                },
                                autoSelected: "zoom"
                            },

                        },

                        noData: {
                            text: 'Loading...'
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',

                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            min: 0,
                            tickAmount: 1,
                            labels: {
                                formatter: function(val) {
                                    return val.toFixed(0)
                                }
                            },
                            title: {
                                text: 'Number'
                            },
                        },
                        xaxis: {
                            title: {
                                text: 'Score Range'
                            },
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },
                        labels:[],


                        fill: {
                            opacity: 1
                        },

                    }
                },
                categories_breakdown:{
                    series: [],
                    chartOptions: {
                        chart: {
                            type: 'donut',
                            offsetY:20,

                        },
                        legend: {
                            position: 'bottom'
                        },
                        labels:[]
                    },

                },
                load1:false,
                load2:false,
                statData: [
                    {
                        icon: "fas fa-users",
                        title: "Teams Registred",
                        value: 0
                    },
                    {
                        icon: "fas fa-award",
                        title: "Total Points",
                        value: 0
                    },
                    {
                        icon: "fas fa-layer-group",
                        title: "Challenges",
                        value: 0
                    }
                ],
            }
        },
        created(){
            this.loadStats()
            this.loadTeamsStats()
        },
        methods:{
            async loadTeamsStats() {
                let stats = await helper.getAll('competitions/' + this.$route.params.id + '/teams/stats');
                this.load1=true
               if(stats.data.teams_count) this.statData[0].value = stats.data.teams_count
                if(stats.data.score_distribution){
                let labels = [];
                let scores = []
                stats.data.score_distribution.forEach(element => {
                    scores.push(element.count);
                    labels.push(element.from + '-' + element.to);
                });

                this.score_distribution.series = [{
                    name: 'Score',
                    data: scores
                }];

                this.score_distribution.chartOptions = {...this.score_distribution.chartOptions, ...{
                        labels: labels
                    }
                }
                }

            },
            async loadStats() {
                let statistics = await helper.getAll('competitions/' + this.$route.params.id + '/stats');
                this.load2=true

               if(statistics.data.solves) {
                   this.$set( this.submissions.series,0,statistics.data.solves)
                   this.$set( this.submissions.series,1,statistics.data.fails)

               }

                this.statData[1].value = statistics.data.totalPoints
                this.statData[2].value = statistics.data.challenges_count


               if(statistics.data.most_solved) this.most_solved = statistics.data.most_solved
                this.namecomp = statistics.data.nameComp


               if(statistics.data.categories)
               {
                   let labels_break  = []
                   Object.values(statistics.data.categories).forEach(element => {
                       this.categories_breakdown.series.push(element);
                   });

                Object.keys(statistics.data.categories).forEach(element => {
                    labels_break.push(element.split('_')[0]);
                });


                this.categories_breakdown.chartOptions = {...this.categories_breakdown.chartOptions, ...{
                        labels: labels_break
                    }}
               }



            }
        }
    }
</script>

<template>
    <div>
        <div class="row" >
            <div class="col-xl-12 m-auto ">
                <div class="page-title-box d-flex ">
                    <h3 class="text-center pt-2">COMPETITION <span class="yellow mx-2" @click="$router.push({name:'ProfilCompetition',params:{id:$route.params.id}})">// {{namecomp}}</span></h3> <span class="gold ml-3 text-center">{{$t('profil_comp.statistics')}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-8">
                <div class="row">
                    <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
                        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value"/>
                    </div>
                </div>

            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-11 m-auto">
                                <div class="media mb-2">
                                    <div class="mr-3">
                                    <i class="fas fa-bahai font-size-24 text-primary mt-2"></i></div>
                                    <div class="media-body">
                                        <p class="text-muted mt-3" v-if="!most_solved.challenge.name && load2">{{$t('validation.no_most')}}</p>
                                        <div class="text-muted" v-else>
                                            <h5 class="mb-2">{{most_solved.challenge.name}}</h5>
                                            <p class="mb-0">Has the most solved challenge with {{most_solved.solves}} solves</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </div>
        <div class="row">
            <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">Submissions Pourcentage</h4>
                                <!-- Donut Chart -->
                                <span class="text-muted row"  v-if="submissions.series.length==0 && load2">
                                    <p class="m-auto">{{$t('validation.no_data')}}</p>
                                </span>
                                <apexchart
                                        v-else
                                        class="apex-charts"
                                        height="370"
                                        type="donut"
                                        dir="ltr"
                                        :series="submissions.series"
                                        :options="submissions.chartOptions"
                                ></apexchart>

                            </div>
                        </div>
            </div>

                    <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">Category Breakdown</h4>
                                <!-- Donut Chart -->
                                <span class="text-muted row" v-if="categories_breakdown.series.length == 0 && load2">
                                    <p class="m-auto">{{$t('validation.no_data')}}</p>
                                </span>
                                <apexchart
                                        v-else
                                        class="apex-charts"
                                        height="370"
                                        type="donut"
                                        dir="ltr"
                                        :series="categories_breakdown.series"
                                        :options="categories_breakdown.chartOptions"
                                ></apexchart>

                            </div>
                        </div>
                    </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Score Distribution</h4>
                        <!-- Line, Column & Area Chart -->
                        <span class="text-muted row" v-if="score_distribution.series.length==0 && load1">
                            <p class="m-auto">{{$t('validation.no_data')}}</p>
                        </span>
                        <apexchart
                                v-else
                                class="apex-charts"
                                height="340"
                                type="line"
                                dir="ltr"
                                :series="score_distribution.series"
                                :options="score_distribution.chartOptions"
                        ></apexchart>

                    </div>
                </div>
            </div>

            </div>



    </div>

</template>

<style scoped>
    .card{
        min-height: 90%!important;
    }
    .yellow{
        color: #ECBD34;
        cursor:pointer;
    }
    .gold{
        background-color: #ECBD34;
        color: #2A2E51;
        font-size: 14px;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        height: 43px;
    }
    .yellow:hover{
        color: #b6daf9;
    }

</style>